<template>
  <h2 class="page-title">Forgot Password</h2>
  <div v-if="success" class="message message-success">Instructions for resetting your password have been sent to your email.</div>
  <form v-if="!success" v-on:submit.prevent="reset">
    <div class="message message-error" v-if="error">{{ error }}</div>
    <div class="form-group">
      <div class="form-input">
        <input type="email" v-model="email" placeholder="Enter Email" v-bind:class="[emailError ? 'error' : '']">
        <div class="form-error" v-if="emailError">{{ emailError }}</div>
      </div>
    </div>
    <div class="form-input form-actions">
      <Loader v-if="isLoading" />
      <button v-if="!isLoading" class="btn" type="submit">Reset Password</button>
    </div>
  </form>
</template>

<script>
import Loader from '@/components/Loader.vue'
import AuthService from '../services/auth.service'
import store from '../store.js'
import validator from '../services/validator'

export default {
  name: 'ForgotPassword',
  components: {
    Loader
  },
  data () {
    return {
      success: false,
      isLoading: false,
      error: '',
      email: '',
      emailError: ''
    }
  },
  created () {
    if (store.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    validate: function () {
      this.emailError = ''
      if (validator.isEmpty(this.email)) {
        this.emailError = 'Email is required.'
        return false
      }
      if (!validator.isValidEmail(this.email)) {
        this.emailError = 'Email is not valid.'
        return false
      }
      return true
    },
    reset: function () {
      if (this.validate()) {
        this.isLoading = true
        AuthService
          .forgotPassword({
            email: this.email
          })
          .then(response => {
            this.isLoading = false
            this.success = true
          })
      }
    }
  }
}
</script>
